// Importar solo lo que usamos
import { initializeApp } from 'firebase/app';

import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from 'firebase/auth';

// Modal functionality
const modal = document.getElementById('loginModal');
const loginForm = document.getElementById('loginForm');

// Firebase config (asegúrate de que firebase ya está cargado via CDN)
// Configuración de Firebase
const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Funciones que necesitan estar en el scope global
window.toggleLoginModal = function () {
  if (modal) {
    modal.style.display = modal.style.display === 'block' ? 'none' : 'block';
  }
};

window.scrollToVideo = function () {
  document.getElementById('video').scrollIntoView({ behavior: 'smooth' });
};

window.scrollToSubscribe = function () {
  document.getElementById('subscribe').scrollIntoView({ behavior: 'smooth' });
};

window.handleLogin = async function (event) {
  event.preventDefault();

  const email = document.getElementById('email').value;
  const password = document.getElementById('password').value;
  const submitBtn = loginForm.querySelector('button[type="submit"]');

  try {
    // Mostrar loading state
    submitBtn.disabled = true;
    submitBtn.textContent = 'Logging in...';

    // Usar signInWithEmailAndPassword directamente, pasando auth como primer argumento
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const token = await userCredential.user.getIdToken();
    localStorage.setItem('auth_token', token);

    // Redirigir a app
    window.location.href = 'https://app.nearsolid.com';
  } catch (error) {
    console.error('Login error:', error);
    alert(error.message);
  } finally {
    submitBtn.disabled = false;
    submitBtn.textContent = 'Login';
  }
};

window.toggleNav = function () {
  const navRight = document.querySelector('.nav-right');
  const navToggleIcon = document.querySelector('.nav-toggle i');

  if (navRight && navToggleIcon) {
    navRight.classList.toggle('active');

    if (navRight.classList.contains('active')) {
      navToggleIcon.classList.remove('fa-bars');
      navToggleIcon.classList.add('fa-times');
    } else {
      navToggleIcon.classList.remove('fa-times');
      navToggleIcon.classList.add('fa-bars');
    }
  }
};

// Event Listeners cuando el DOM está listo
document.addEventListener('DOMContentLoaded', () => {
  const loginBtn = document.querySelector('.login-btn');
  const closeBtn = document.querySelector('.modal .close');
  const loginForm = document.getElementById('loginForm');
  // Nav toggle listener
  const navToggle = document.querySelector('.nav-toggle');
  if (navToggle) {
    navToggle.addEventListener('click', window.toggleNav);
  }

  if (loginBtn) {
    loginBtn.addEventListener('click', window.toggleLoginModal);
  }

  if (closeBtn) {
    closeBtn.addEventListener('click', window.toggleLoginModal);
  }

  if (loginForm) {
    loginForm.addEventListener('submit', window.handleLogin);
  }

  // Cerrar modal cuando se hace click fuera
  window.addEventListener('click', (event) => {
    if (event.target === modal) {
      modal.style.display = 'none';
    }
  });

  // Manejar la reproducción de videos
  const videoContainers = document.querySelectorAll('.feature-video-container');
  const isMobile = window.matchMedia('(hover: none)').matches;

  videoContainers.forEach((container) => {
    const video = container.querySelector('video');
    const overlay = container.querySelector('.play-overlay');

    if (isMobile) {
      // Comportamiento para móvil (tap para reproducir)
      overlay.addEventListener('click', async () => {
        try {
          if (video.paused) {
            const playPromise = video.play();
            if (playPromise !== undefined) {
              await playPromise;
              overlay.classList.add('playing');
            }
          } else {
            video.pause();
            overlay.classList.remove('playing');
          }
        } catch (error) {
          console.log('Error en reproducción:', error);
        }
      });

      // Reiniciar cuando el video termina
      video.addEventListener('ended', () => {
        overlay.classList.remove('playing');
      });
    } else {
      // Comportamiento para desktop (hover)
      container.addEventListener('mouseenter', async () => {
        try {
          const playPromise = video.play();
          if (playPromise !== undefined) {
            await playPromise;
            overlay.style.opacity = '0';
          }
        } catch (error) {
          console.log('Reproducción interrumpida:', error);
        }
      });

      container.addEventListener('mouseleave', () => {
        if (video.played.length > 0) {
          video.pause();
          video.currentTime = 0;
          overlay.style.opacity = '1';
        }
      });
    }
  });

  // Botones de scroll
  const subscribeBtn = document.querySelector('.primary-btn');
  const videoBtn = document.querySelector('.secondary-btn');

  if (subscribeBtn) {
    subscribeBtn.addEventListener('click', window.scrollToSubscribe);
  }

  if (videoBtn) {
    videoBtn.addEventListener('click', window.scrollToVideo);
  }
});
